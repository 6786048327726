<template>
  <div v-if="!isLoading && !pendingRequestCount && isCompleted">
    <div class="bg-black border-0 sm:border-b border-gray-600
    grid grid-cols-1 sm:grid-cols-6">
      <div class="flex flex-col py-3 px-4 print:hidden
      col-span-5 border-b border-gray-600 sm:border-0">
        <div>
          <h1 class="inline-block uppercase font-medium text-lg sm:text-2xl
                   print:tracking-widest print:text-black print:font-semibold">
            Executive Report - {{ String(currentReportID) | zeroPad(4) }}
          </h1>
        </div>
        <div class="uppercase font-semibold tracking-widest text-xs text-gray-300">
          Submitted {{ executiveReport.createdAt | formatDate }}
        </div>
      </div>
      <div class="sm:border-l sm:w-auto flex justify-center border-gray-600">
          <Button type="text-small"
                  class="self-stretch px-4 py-4"
                  @onClick="showModal = !showModal">
            <template v-slot:iconLeft>
              <Print class="text-gray-300" />
            </template>
            Create Subset Report
          </Button>
        </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-4 xl:grid-cols-5
                bg-black border-b border-gray-600
                print:bg-white print:border-0"
      :class='{
        "mb-8": !showTrialView,
        "mb-6": showTrialView,
      }'
    >
      <!-- Print Version -->
      <div class="hidden flex-row py-3 row-start-1 col-start-1 uppercase font-semibold text-sm
                  tracking-widest
                  print:flex">
        <div class="flex flex-col mr-8">
          <div class="text-gray-300">
            Submitted
          </div>
          <div class="text-gray-700">
            {{ executiveReport.createdAt | formatDate }}
          </div>
        </div>
        <div class="flex flex-col">
          <div class="text-gray-300">
            Input
          </div>
          <div class="text-gray-700">
            <span v-for="(input, index) in executiveReport.reportInput"
                  :key="`input${index}`">
              {{ input }}
            </span>
          </div>
        </div>
      </div>
      <!-- End Print Version -->
      <div class="flex flex-col row-start-2 col-start-1 col-end-3 border-t
                  sm:row-start-1 sm:border-t-0
                  py-3 px-4 border-gray-600
                  print:hidden">
        <div class="uppercase font-semibold tracking-widest text-xs text-gray-300">
          Input
        </div>
        <div>
          <span v-for="(input, index) in executiveReport.reportInput"
                :key="`input${index}`">
            {{ input }}
          </span>
        </div>
      </div>
      <div
        v-if="isSuperUser && executiveReport && executiveReport.finishedAt"
        class="flex flex-col row-start-2 col-start-3 col-end-4 border-t
                  sm:row-start-1 sm:border-t-0
                  py-3 px-4 border-gray-600
                  print:hidden">
        <div class="uppercase font-semibold tracking-widest text-xs text-gray-300">
          Runtime
        </div>
        <div>
          {{ this.executiveReport.createdAt | formatRuntime(this.executiveReport.finishedAt) }}
        </div>
      </div>
      <div v-if="isSuperUser && currentReportInitiator"
           class="flex flex-col row-start-2 col-start-4 col-end-6 border-t
                  sm:row-start-1 sm:border-t-0
                  py-3 px-4 border-gray-600">
        <div class="uppercase font-semibold tracking-widest text-xs text-gray-300">
          Initiated By:
        </div>
        <div>
          {{ currentReportInitiator }}
        </div>
      </div>
      <div class="flex flex-row-reverse sm:flex-row items-center border-gray-600
                  row-start-3 col-start-1 col-span-5 border-t
                  sm:row-start-1 sm:col-start-7 sm:col-span-2 sm:border-t-0
                  print:hidden">
        <div v-if="pastReportID"
             class="flex flex-col w-full py-3 px-4 border-gray-600 border-l">
          <div class="flex justify-end">
          </div>
          <div class="flex justify-end">
            <Button type="text-small"
                    @onClick="$router.push(
                      { name: 'executive-report',
                        params: {
                          execReport: pastReportID,
                        },
                      })">
              Report {{ String(pastReportID) | zeroPad(4) }}
              <template v-slot:iconRight>
                <ArrowRight class="text-gray-300" />
              </template>
            </Button>
          </div>
        </div>
        <div class="flex w-full items-center justify-center self-stretch
                    border-l sm:w-auto
                    border-gray-600">
          <Button type="text-small"
                  class="self-stretch px-4"
                  @onClick="handlePrint">
            <template v-slot:iconLeft>
              <Print class="text-gray-300" />
            </template>
            Print
          </Button>
        </div>
      </div>
    </div>
    <div class="
      flex flex-col w-100 mb-12 text-center border border-yellow-500
      py-3 mx-4 bg-gray-900
    "  v-if="showTrialView">
      <h2 class="uppercase font-semibold tracking-widest">Trial Mode</h2>
      <p
        class="text-gray-200">
        You are seeing partial results for this report because your account is in Trial Mode.
      </p>
    </div>
    <div class="flex justify-center items-center mb-12 max-w-screen-lg
                print:mb-8">
      <AttackPathwaysWrapper
        class="sm:mx-24 print:mx-0"
        :attackPathwaysCount="attackPathways.length"
        :resolvedAttackPathwaysCount="resolvedAttackPathways.length"
        :isShowingPrevious="isShowingPrevious"
      >
        <template v-slot:pathways>
          <AttackPathway v-for="(attackPathway, index) in attackPathways"
                         :outcome="attackPathway.attackPathwayRule.outcome"
                         :rules="attackPathway.attackPathwayRule.rules"
                         :findings="attackPathwayFindings"
                         :class="[
                                   {'mt-4': index !== 0},
                                 ]"
                         :newAttackPathways="newAttackPathways"
                         :reportDomain="executiveReport.reportInput[0]"
                         :domains="domains"
                         :executiveReportID="String(currentReportID)"
                         :key="`attack-pathway-${index}`"/>
        </template>
        <template v-slot:resolvedPathways>
          <AttackPathway v-for="(attackPathway, index) in resolvedAttackPathways"
                         :outcome="attackPathway.attackPathwayRule.outcome"
                         :rules="attackPathway.attackPathwayRule.rules"
                         :findings="pastFindings"
                         :class="[
                                 {'mt-4': index !== 0},
                               ]"
                         :isResolved="true"
                         :domains="domains"
                         :key="`resolved-attack-pathway-${index}`"/>
        </template>
      </AttackPathwaysWrapper>
    </div>
    <div class="grid gap-6 grid-cols-1 md:grid-cols-2 mx-6 sm:mx-8 mb-8 max-w-screen-lg
                print:mx-0 print:gap-2">
      <div class="mb-6">
        <Findings :findings="findings"
                  :resolvedFindings="resolvedFindings"
                  :showResolved="isShowingPrevious"/>
      </div>
      <div>
        <Summary
                 :externalHosts="externalHosts || 0"
                 :internalHosts="internalHosts || 0"
                 :technologies="technologies"
                 :previousTechnologies="previousTechnologies"
                 :domainCount="domains.length"
                 :showPrevious="isShowingPrevious"/>
      </div>
    </div>
    <div class="flex flex-col border border-gray-600 bg-gray-900 mx-6 sm:mx-8 mb-8 max-w-screen-lg
                print:mx-0">
      <div class="-mt-4 ml-3 mb-4">
        <h2 class="triangle relative inline-flex uppercase pl-3 pr-2 py-1 bg-gray-900
                 border-t border-l border-b border-gray-600">
          <span class="relative inline-block z-10 text-2xl">
            Domains Found [{{ domains.length }}]
          </span>
        </h2>
      </div>
      <div class="grid col-gap-3 row-gap-4 grid-cols-1 md:grid-cols-2 ml-3 mr-4 mb-4 mt-2">
        <DomainCard v-for="(domain, index) in domains"
                    :executiveReportID="String(currentReportID)"
                    :id="domain.id"
                    :name="domain.name"
                    :findingsCount="domain.findingsCount"
                    :attackPathwaysCount="domain.attackPathwaysCount"
                    :screenshots="domain.screenshots"
                    :key="`${currentReportID}-domain${index}`"
                    :hostCount="domain.hostCount"
                    :status='domain.status'
        />
      </div>
    </div>
    <div
      v-if="relatedApexDomainsChecklist && relatedApexDomainsChecklist.length"
      class="flex flex-col border border-gray-600 bg-gray-900 mx-6 sm:mx-8 mb-8 max-w-screen-lg
                print:mx-0">
      <div class="-mt-4 ml-3 mb-4">
        <h2 class="triangle relative inline-flex uppercase pl-3 pr-2 py-1 bg-gray-900
                 border-t border-l border-b border-gray-600">
          <span class="relative inline-block z-10 text-2xl">
            Additional Domains Found [{{ relatedApexDomainsChecklist.length }}]
          </span>
        </h2>
      </div>
      <div class="ml-3 mr-4 mb-4">
        <div class="border border-gray-600 p-2">
          <p class="text-sm text-gray-200">
            Orbital reconnaissance discovered the following related domains but your
            organization does not have access to run reports on them.
            Select the domains you'd like to run reports on and click "Request Access"
            to have the domains added to your organization's Domain Allow List.
          </p>
          <div
            v-if="requestAdditionalDomainsStatus === 'success'"
            class="bg-green-900 inline-block py-1 px-2 text-sm mt-4 mb-2">
            Request sent! We'll be reaching out to you shortly.
          </div>
          <div
            v-else-if="requestAdditionalDomainsStatus === 'error' && !selectedRelatedDomains.length"
            class="bg-red-900 inline-block py-1 px-2 text-sm mt-4 mb-2">
            Error sending request. Refresh the page and try again.
          </div>
          <Button
            v-else-if="selectedRelatedDomains.length"
            @onClick="() => sendRelatedDomainsRequest()"
            class="mb-2 mt-4 block"
            :inFlight="requestAdditionalDomainsStatus === 'loading'"
            :disabled="!selectedRelatedDomains && !selectedRelatedDomains.length"
            type="primary"
          >Send Request</Button>
        </div>
        <div class="block domain-checklist-wrapper mt-4">
          <div
            class="text-gray-100 "
            v-for="(domain, index) in relatedApexDomainsChecklist"
            :key="domain.domain">
            <Checkbox :id="domain.domain"
                      :checked="domain.isSelected"
                      @change="updatedDomainCheckedStatus(index)"
                      class="uppercase text-xs"
                      :label="domain.domain" />
          </div>
        </div>
      </div>
    </div>
    <AdminModal
      v-if="showAdminModal"
      :reportId='Number(currentReportID)'
      :findings="findingsAll || []"
      :attackPathwayFindings="attackPathwaysAll"
      :parentReportID="currentReportID"
      @close='handleClose'
    />
    <SubsetModal
      v-if="showModal"
      :domains="domains"
      @close='handleClose'
    />
  </div>
  <div v-else-if="(!isLoading && !pendingRequestCount) && isFailed"
       class="flex justify-center items-center my-12 max-w-screen-lg flex-col
              print:mb-8">
      <ReportErrorMessage
        class="mx-8"
        key="failed"
        @showDeleteModal='showDeleteReportModal = !showDeleteReportModal'>
        <template v-slot:main>
          Report {{ String(currentReportID) | zeroPad(4) }} Failed
        </template>
        <template v-slot:additional>
          <template v-if="executiveReport.hasErrors">
            <div class="text-center">[ Errors Encountered ]</div>
            <div v-for="(error, index) in executiveReport.errors"
                 :key="`error_${index}`">
              <template v-if="error.errorPayload && error.errorPayload.errors">
                [{{index }}] {{ error.source }}
                <ul class="ml-2 text-sm">
                  <li v-for="(detailedError, detailedIndex) in error.errorPayload.errors"
                      :key="`errorDetail-${detailedIndex}`">
                    [{{index}}.{{detailedIndex}}] {{ detailedError }}
                  </li>
                </ul>
              </template>
            </div>
          </template>
          <template v-if="!executiveReport.isAcceptingWebTraffic">
            [ Not Accepting Web Traffic ]
          </template>
        </template>
      </ReportErrorMessage>
      <DeleteReportModal
        v-if="showDeleteReportModal"
        :reportId='executiveReport.id'
        @close='handleClose'/>
  </div>
  <div v-else-if="(!isLoading && !pendingRequestCount) && !executiveReport"
       class="flex justify-center items-center my-12 max-w-screen-lg
              print:mb-8">
    <ReportErrorMessage key="missing">
      <template v-slot:main>
        Report Missing
      </template>
    </ReportErrorMessage>
  </div>
  <div v-else-if="isInFlight && !isLoading" class="flex flex-col flex-grow">
    <div class="grid grid-cols-1 sm:grid-cols-4 xl:grid-cols-6
                bg-black border-b border-gray-600 mb-12
                print:bg-white print:border-0">
      <div class="flex flex-col py-3 px-4 row-start-1 col-start-1 print:hidden">
        <div>
          Report {{ String(currentReportID) | zeroPad(4) }}
        </div>
        <div class="uppercase font-semibold tracking-widest text-xs text-gray-300">
          Submitted {{ executiveReport.createdAt | formatDate }}
        </div>
      </div>
      <!-- Print Version -->
      <div class="hidden flex-row py-3 row-start-1 col-start-1 uppercase font-semibold text-sm
                  tracking-widest
                  print:flex">
        <div class="flex flex-col mr-8">
          <div class="text-gray-300">
            Submitted
          </div>
          <div class="text-gray-700">
            {{ executiveReport.createdAt | formatDate }}
          </div>
        </div>
        <div class="flex flex-col">
          <div class="text-gray-300">
            Input
          </div>
          <div class="text-gray-700">
            <span v-for="(input, index) in executiveReport.reportInput"
                  :key="`input${index}`">
              {{ input }}
            </span>
          </div>
        </div>
      </div>
      <!-- End Print Version -->
      <div class="flex flex-col row-start-2 col-start-1 col-end-3 border-t
                  sm:row-start-1 sm:col-start-2 sm:border-l sm:border-t-0
                  py-3 px-4 border-gray-600
                  print:hidden">
        <div class="uppercase font-semibold tracking-widest text-xs text-gray-300">
          Input
        </div>
        <div>
          <span v-for="(input, index) in executiveReport.reportInput"
                :key="`input${index}`">
            {{ input }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex flex-grow items-start justify-end">
      <ProgressReport class="w-full mx-8 md:w-5/12 md:mt-16"
                      :step="executiveReport && executiveReport.progress || 0" />
    </div>
  </div>
  <div v-else-if="isLoading"
       class="flex flex-grow items-start fade-in">
    <div
         class="relative top-0 left-0 w-full h-full">
      <div class="background-wrapper background-wrapper--step2">
        <BackgroundImage class="background" />
      </div>
    </div>
    <div class="w-full mx-8 md:w-5/12 md:mt-32 text-center">
      <h2 class="uppercase text-2xl mb-2">
        Loading...
      </h2>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AdminModal from '@/components/AdminModal.vue';
import BackgroundImage from '@/assets/new-report-bg.svg?inline';
import SubsetModal from '@/components/SubsetModal.vue';
import DeleteReportModal from '@/components/DeleteReportModal.vue';
import ArrowRight from '@/assets/arrow_right.svg?inline';
import AttackPathway from '@/components/AttackPathway.vue';
import AttackPathwaysWrapper from '@/components/AttackPathwaysWrapper.vue';
import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';
import DomainCard from '@/components/DomainCard.vue';
import Findings from '@/components/Findings.vue';
import Print from '@/assets/print.svg?inline';
import ProgressReport from '@/components/ProgressReport.vue';
import ReportErrorMessage from '@/components/ReportErrorMessage.vue';
import Summary from '@/components/Summary.vue';

export default {
  name: 'ExecutiveReport',
  components: {
    AdminModal,
    BackgroundImage,
    SubsetModal,
    ArrowRight,
    AttackPathway,
    AttackPathwaysWrapper,
    Button,
    Checkbox,
    DeleteReportModal,
    DomainCard,
    Findings,
    Print,
    ProgressReport,
    ReportErrorMessage,
    Summary,
  },
  data() {
    return {
      pollingCount: 0,
      isShowingPrevious: false,
      showRemovedHosts: true,
      timeout: null,
      showAdminModal: false,
      showModal: false,
      showDeleteReportModal: false,
      relatedApexDomainsChecklist: [],
      relatedDomainsRequestSuccess: false,
    };
  },
  mounted() {
    if (this.currentReportID) {
      this.fetchExecReport(this.currentReportID);
    }
  },
  computed: {
    ...mapGetters([
      'executiveReport',
      'reports',
      'isLoading',
      'pastReport',
      'isSuperUser',
      'pendingRequestCount',
      'requestAdditionalDomainsStatus',
      'currentUser',
      'selectedView',
      'currentReportInitiator',
    ]),
    isOnNewReportPage() {
      return this.$route.name === 'new-report';
    },
    isInFlight() {
      return this.executiveReport && this.executiveReport.status === 'in_flight';
    },
    isFailed() {
      return this.executiveReport && this.executiveReport.status === 'failed';
    },
    isCompleted() {
      return this.executiveReport && this.executiveReport.status === 'completed';
    },
    currentReportID() {
      return this.$route.params.execReport;
    },

    showTrialView() {
      return this.selectedView === 'customer' && this.currentUser.organization.isTrial;
    },
    pastReportID() {
      return this.executiveReport
        && this.executiveReport.pastReportDiff
        && this.executiveReport.pastReportDiff.id;
    },
    pollingInterval() {
      let interval = 10000; // 10 seconds

      // Over one minute
      if (this.pollingCount > 5) {
        interval = 30000; // 30 seconds
      }

      // Over 3 minutes
      if (this.pollingCount > 9) {
        interval = 60000; // 1 minute
      }

      // Over 10 minutes
      if (this.pollingCount > 16) {
        interval = 300000; // 5 minutes
      }

      return interval;
    },
    findings() {
      if ((this.executiveReport && this.executiveReport.findings)
          || this.executiveReport.children.length > 1) {
        if (this.executiveReport.children.length > 1) {
          const findings = this.executiveReport.findings || [];
          return findings
            .concat(this.executiveReport.children.reduce(
              (accumulator, child) => {
                if (child.findings) {
                  return accumulator.concat(child.findings);
                }
                return accumulator;
              },
              [],
            ))
            .filter((finding) => finding.visible);
        }

        return this.executiveReport
        && this.executiveReport.findings
        && this.executiveReport.findings
          .filter((finding) => finding.visible);
      }

      return [];
    },
    findingsAll() {
      if (this.executiveReport && this.executiveReport.findings) {
        if (this.executiveReport.children.length > 1) {
          return this.executiveReport.findings
            .concat(this.executiveReport.children.reduce(
              (accumulator, child) => accumulator.concat(child.findings),
              [],
            ));
        }

        return this.executiveReport
          && this.executiveReport.aggregatedResult
          && this.executiveReport.findings
            .concat(this.executiveReport.findings.reduce(
              (accumulator, finding) => {
                if (finding) {
                  return accumulator.concat(finding);
                }
                return accumulator;
              },
              [],
            ))
            .filter((finding) => finding.visible);
      }

      if (this.executiveReport && this.executiveReport.children) {
        return this.executiveReport
          && this.executiveReport.children
          && this.executiveReport.children.concat(this.executiveReport.findings.reduce(
            (accumulator, finding) => {
              if (finding) {
                return accumulator.concat(finding);
              }
              return accumulator;
            }, [],
          ))
            .filter((finding) => finding.visible);
      }

      return [];
    },
    /*
      This exists because the Attack Pathways needs to domains associated with the findings.
    */
    attackPathwayFindings() {
      if (this.executiveReport && this.executiveReport.executiveSummary) {
        return this.executiveReport.executiveSummary.findings;
      }

      if (this.executiveReport
          && this.executiveReport.aggregatedResult
          && this.executiveReport.children) {
        return this.executiveReport.aggregatedResult.findings
          .concat(this.executiveReport.children.reduce(
            (accumulator, child) => accumulator.concat(child.aggregatedResult.findings),
            [],
          ));
      }

      return this.executiveReport
        && this.executiveReport.aggregatedResult
        && this.executiveReport.aggregatedResult.findings;
    },
    pastFindings() {
      return (this.pastReport
        && this.pastReport.aggregatedResult
        && this.pastReport.aggregatedResult.findings)
        || (this.pastReport
          && this.pastReport.children
          && this.pastReport.children.reduce(
            (accumulator, child) => accumulator.concat(child.aggregatedResult.findings),
            [],
          ));
    },
    resolvedFindings() {
      if (this.executiveReport
        && this.executiveReport.pastReportDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0]
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemRemoved) {
        // eslint-disable-next-line max-len
        const removed = this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemRemoved;
        const findingsKeys = Object.keys(removed).filter((key) => key.includes('findings'));
        const findingsArray = [];
        findingsKeys.forEach((key) => findingsArray.push(removed[key]));
        return findingsArray;
      }
      return [];
    },
    externalHosts() {
      if (this.executiveReport && this.executiveReport.executiveSummary) {
        return this.executiveReport.executiveSummary.externalIpsCount;
      }
      return (this.executiveReport
        && this.executiveReport.externalIpsCount)
        || (this.executiveReport.summary
        && this.executiveReport.summary.externalIpsCount);
    },
    internalHosts() {
      if (this.executiveReport && this.executiveReport.executiveSummary) {
        return this.executiveReport.executiveSummary.internalIpsCount;
      }
      return (this.executiveReport
        && this.executiveReport.internalIpsCount)
        || (this.executiveReport.summary
        && this.executiveReport.summary.internalIpsCount);
    },
    technologies() {
      if (this.executiveReport && this.executiveReport.executiveSummary) {
        return this.executiveReport.executiveSummary.technologies;
      }
      return (this.executiveReport
        && this.executiveReport.technologies)
        || (this.executiveReport.summary
        && this.executiveReport.summary.technologies)
        || [];
    },
    previousTechnologies() {
      if (this.executiveReport
        && this.executiveReport.pastReportDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0]
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemRemoved) {
        // eslint-disable-next-line max-len
        const removed = this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemRemoved;
        const techKeys = Object.keys(removed).filter((key) => key.includes('technologies'));
        const techArray = [];
        techKeys.forEach((key) => techArray.push(removed[key]));
        return techArray;
      }
      return [];
    },
    attackPathways() {
      const allFindings = (this.executiveReport
        && this.executiveReport.executiveSummary
        && this.executiveReport.executiveSummary.attackPathwayFindings
          .filter((finding) => finding.visible))
        || (this.executiveReport
          && this.executiveReport.aggregatedResult
          && this.executiveReport.aggregatedResult.attackPathwayFindings
            .filter((finding) => finding.visible));
      return Array.from(
        new Map(allFindings.map((finding) => {
          console.log(finding);
          return [finding.attackPathwayRule?.id, finding];
        })).values(),
      );
    },
    attackPathwaysAll() {
      if (this.executiveReport
        && this.executiveReport.executiveSummary
        && this.executiveReport.executiveSummary.attackPathwayFindings.length) {
        return this.executiveReport.executiveSummary.attackPathwayFindings;
      }

      if (this.executiveReport
          && this.executiveReport.aggregatedResult
          && this.executiveReport.aggregatedResult.attackPathwayFindings.length) {
        return this.executiveReport.aggregatedResult.attackPathwayFindings;
      }

      return [];
    },
    newAttackPathways() {
      if (this.executiveReport
        && this.executiveReport.pastReportDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0]
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemAdded) {
        // eslint-disable-next-line max-len
        const added = this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemAdded;
        const attackPathKeys = Object.keys(added).filter((key) => key.includes('attackPathwayFindings'));
        const pathwaysArray = [];
        attackPathKeys.forEach((key) => pathwaysArray.push(added[key]));
        return pathwaysArray;
      }
      return [];
    },
    resolvedAttackPathways() {
      if (this.executiveReport
        && this.executiveReport.pastReportDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0]
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemRemoved) {
        // eslint-disable-next-line max-len
        const removed = this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemRemoved;
        const attackPathKeys = Object.keys(removed).filter((key) => key.includes('attackPathwayFindings'));
        const pathwaysArray = [];
        attackPathKeys.forEach((key) => pathwaysArray.push(removed[key]));
        return pathwaysArray;
      }
      return [];
    },
    selectedRelatedDomains() {
      if (this.relatedApexDomainsChecklist) {
        const filteredDomains = this.relatedApexDomainsChecklist
          .filter((domain) => domain.isSelected) || null;
        return filteredDomains;
      }
      return null;
    },
    domains() {
      if (!this.executiveReport) return [];
      const domains = [];
      try {
        this.executiveReport.children.forEach((child) => {
          domains.push({
            id: child.id,
            name: child.miscResourceReport ? child.reportInput.join(' ') : child.reportInput[0],
            findingsCount: child.findings ? child.findings.length : 0,
            attackPathwaysCount: child.attackPathwayFindingsCount,
            screenshots: child.screenshots,
            hostCount: child.hostCount,
            status: child.status,
          });
        });
      } catch (e) {
        console.log(e);
      }

      if (domains && domains.length === 0) {
        domains.push({
          id: this.executiveReport.id,
          name: this.executiveReport.miscResourceReport ? this.executiveReport.reportInput.join(' ') : this.executiveReport.reportInput[0],
          findingsCount: this.executiveReport.findings ? this.executiveReport.findings.length : 0,
          attackPathwaysCount: this.executiveReport.summary.attackPathwaysCount,
          screenshots: this.executiveReport.screenshots,
          hostCount: this.executiveReport.hostCount,
          status: this.executiveReport.status,
        });
      }
      return domains;
    },
  },
  methods: {
    ...mapActions([
      'fetchReport',
      'fetchExecReport',
      'fetchPastReport',
      'fetchReports',
      'recollateReport',
      'requestAdditionalDomains',
      'updateRequestAdditionalDomainStatus',
    ]),
    populateRelatedDomains() {
      const domains = this.executiveReport.relatedApexDomains || [];
      const childDomains = this.executiveReport.children.reduce(
        (acc, { relatedApexDomains }) => [...relatedApexDomains, ...acc], [],
      );
      const sortedDomains = [
        ...new Set(
          [...domains, ...childDomains],
        ),
      ].sort((a, b) => (a < b ? -1 : 1));
      this.relatedApexDomainsChecklist = sortedDomains.map((domain) => ({
        domain,
        isSelected: false,
      }));
    },
    resetData() {
      this.pollingCount = 0;
      clearTimeout(this.timeout);
      this.timeout = null;
      this.updateRequestAdditionalDomainStatus(null);
    },
    handlePrint() {
      window.print();
    },
    handleClose(redirectRoute, shouldFetchReport = false) {
      this.showAdminModal = false;
      this.showModal = false;
      this.showDeleteReportModal = false;
      if (redirectRoute) {
        this.$router.push({ name: redirectRoute });
      }

      if (shouldFetchReport) {
        this.fetchExecReport(this.currentReportID);
      }
    },
    sendRelatedDomainsRequest() {
      const report = this.currentReportID;
      const filteredDomains = this.relatedApexDomainsChecklist
        .filter((domain) => domain.isSelected);
      const domains = filteredDomains.map(({ domain }) => domain);
      this.requestAdditionalDomains({ domains, report }).then(() => {
        this.relatedApexDomainsChecklist.forEach((domain) => {
          domain.isSelected = false;
        });
      });
    },
    updatedDomainCheckedStatus(index) {
      const newValue = !this.relatedApexDomainsChecklist[index].isSelected;
      this.relatedApexDomainsChecklist[index].isSelected = newValue;
      if (this.requestAdditionalDomainsStatus) this.updateRequestAdditionalDomainStatus(null);
    },
  },
  beforeDestroy() {
    this.resetData();
  },
  watch: {
    $route() {
      this.resetData();
      if (this.currentReportID) {
        this.fetchExecReport(this.currentReportID);
      }
    },
    async executiveReport(newReport) {
      /* Stop the polling from happening if we're on a new report */
      if (String(newReport.id) !== String(this.currentReportID)) {
        return;
      }

      if (this.isInFlight) {
        this.pollingCount += 1;
        this.timeout = setTimeout(
          () => {
            if (this.currentReportID) {
              this.fetchExecReport(this.currentReportID);
            }
          },
          this.pollingInterval,
        );
      }

      if (this.isCompleted) {
        this.relatedApexDomainsChecklist = [];
        this.populateRelatedDomains();
        if (this.pastReportID) {
          await this.fetchPastReport(this.pastReportID);
        }
      }
    },
  },
};
</script>

<style scoped>
.background {
  @apply max-w-screen-lg w-full h-full;
  animation: spin 120s linear infinite;
  transform-origin: center center;
}

.triangle::before {
  @apply absolute border-b bg-gray-900 border-t border-gray-600 border-r z-0
  transform skew-x-20;
  top: -1px;
  left: calc(100% - 0.5rem);
  width: calc(1rem + 5px);
  height: calc(100% + 2px);
  content: '';
}
  .domain-checklist-wrapper {
    max-height: 25rem;
    overflow-y: auto;
  }

@media print {
  .domain-checklist-wrapper {
    max-height: unset;
  }
}

.background-wrapper--step2 {
  transform: translate(-10%, 10%) scale(0.6);
}

.background-wrapper--step1 {
  transform: translateY(20%) scale(1);
}

.fade-in {
  animation: enter 1000ms linear both;
  animation-delay: 1s;
}
</style>
