<template>
  <div
    class="grid grid-cols-1 relative overflow-hidden"
    >
    <div class="text-center w-7/8 sm:w-8/12 mx-auto relative">
      <div class="absolute left-0 right-0 flex justify-center items-center z-10 mt-16">
        <div class="
          border-grey-100 border-2 bg-gray-900 py-1 px-5 transform skew-x-20
        ">
          <h2 class="text-white uppercase text-2xl font-medium transform -skew-x-20">
            New Orbital Report
          </h2>
        </div>
      </div>
      <div class="
        border border-gray-500 text-center mx-auto relative mt-24 p-10
        pt-0 bg-gray-900 right-0 left-0 bottom-0
      ">
        <div class="text-white mt-10">
          <p>[INSTRUCTIONS]</p>
          <p v-if="adminView">Enter one or more comma-separated domain names to target.</p>
          <p v-else>Select domain names to target.</p>
        </div>
        <div class="mt-4">
          <Checkbox
            v-if="currentUser && currentUser.isSuperuser"
            id="admin-view-toggle`"
            label="Show text input [Admin Only]"
            v-model="adminView"/>
        </div>
        <div class="mt-6">
          <div v-if="adminView">
            <TextInput
              type="text"
              label="Target"
              placeholder="example.com, otherexample.com"
              v-model="reportInput"
              @keyup.enter.native="submitTextInputReport()"
            />
            <Button
              @onClick="() => submitTextInputReport()"
              class="mt-8"
              type="primary"
              :disabled="selectedDomains.length < 1 && !adminView"
            >Launch Orbit</Button>
          </div>
          <div
            v-else
            class="mt-6">
            <template v-if="domainList.length">
              <div class="w-full flex justify-between">
                <SelectAllToggle
                  class="ml-4"
                  :allItemsCount="domainList.length"
                  :selectedItemsCount="selectedDomains.length"
                  @click="toggleAllDomainSelections" />
                <div  v-if="domainList && domainList.length"
                      class="text-gray-200">
                  {{ domainList.length }} {{'domain' | pluralize(domainList.length) }}
                </div>
              </div>
              <div class="grid grid-rows-6 gap-2 grid-flow-col mt-4 border border-gray-600 p-4">
                <div v-for="(domain, index) in domainList"
                     class="text-left"
                     :key="index">
                  <Checkbox
                    :id="`domain-${domain.domain}`"
                    :label="domain.domain"
                    v-model="domain.isSelected"/>
                </div>
              </div>
              <Button
                @onClick="() => submitSelectedDomainsReport()"
                class="mt-8"
                type="primary"
                :disabled="selectedDomains.length < 1 && !adminView"
              >Launch Orbit</Button>
            </template>
            <template v-else>
              <p class="text-gray-300 italic py-4">Your organization has no approved domains.</p>
            </template>
          </div>
          <div
            v-if="selectedDomains && selectedDomains.length && !adminView"
            class="mt-4 text-gray-200">
            {{ selectedDomains.length }} selected
            {{selectedDomains.length > 1 ? 'domains' : 'domain'}}
          </div>
          <div
            v-if="newReportError
              && newReportError.reportInput
              && newReportError.reportInput.length > 0"
            class="mt-8 text-red-600">
            <ErrorMessage :error='newReportError.reportInput[0]' />
          </div>
          <div
            v-else-if="newReportError"
            class="mt-8 text-red-600">
            {{ newReportError }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import SelectAllToggle from '@/components/SelectAllToggle.vue';
import TextInput from '@/components/TextInput.vue';

export default {
  name: 'NewReport',
  components: {
    Button,
    Checkbox,
    ErrorMessage,
    SelectAllToggle,
    TextInput,
  },
  computed: {
    ...mapGetters(['isLoading', 'newReportError', 'executiveReport', 'currentUser']),
    selectedDomains() {
      return this.domainList.filter((d) => d.isSelected).map((d) => d.domain);
    },
    toggleValue() {
      const { selectedDomains, domainList } = this;
      if ((selectedDomains && domainList) && selectedDomains.length === domainList.length) return 'deselect';
      return 'select';
    },
  },
  data() {
    return {
      adminView: false,
      reportInput: null,
      domainList: [],
    };
  },
  created() {
    this.updateNewReportError(null);
    this.$store.commit('CLEAR_EXECUTIVE_REPORT', true);
  },
  methods: {
    ...mapActions(['submitNewReport', 'updateNewReportError', 'fetchReports']),
    submitTextInputReport() {
      if (!this.reportInput) {
        const error = 'Report input must not be null';
        return this.updateNewReportError(error);
      }
      const reportInput = this.reportInput.replace(/\s/g, '').split(',');
      return this.submitReport(reportInput);
    },
    submitSelectedDomainsReport() {
      if (!this.selectedDomains || !this.selectedDomains.length) {
        const error = 'Report input must not be null';
        return this.updateNewReportError(error);
      }
      const reportInput = this.selectedDomains;
      return this.submitReport(reportInput);
    },
    submitReport(input) {
      if (!input) {
        const error = 'Report input must not be null';
        return this.updateNewReportError(error);
      }
      return this.submitNewReport(input)
        .then(() => {
          this.fetchReports(undefined, false);
        });
    },
    toggleAllDomainSelections() {
      const toggleValue = this.toggleValue === 'select';
      this.domainList.forEach((domain) => {
        domain.isSelected = toggleValue;
      });
    },
    populateDomainList(domains) {
      const formattedDomains = domains.map((domain) => {
        const formattedDomain = {
          domain,
          isSelected: false,
        };
        return formattedDomain;
      });
      this.domainList = formattedDomains;
    },
  },
  watch: {
    executiveReport() {
      if (this.executiveReport && this.executiveReport.id) {
        this.$router.push(`/report/${this.executiveReport.id}`);
      }
    },
    currentUser() {
      if (this.currentUser && this.currentUser.organization && !this.domainList.length) {
        this.populateDomainList(this.currentUser.organization.whitelist);
      }
    },
  },
  mounted() {
    if (this.currentUser && this.currentUser.organization && !this.domainList.length) {
      console.log(this.currentUser.organization.whitelist);
      this.populateDomainList(this.currentUser.organization.whitelist);
    }
  },
};
</script>

<style scoped>
  .select-toggle-indicator::after {
    @apply absolute w-2 h-2 top-0 left-0 rounded-sm bg-white;
    content: "";
    left: 3px;
    top: 3px;
    transition: background-color 0.2s ease, opacity 0.2s ease;
  }

  .deselected-toggle::after {
    @apply opacity-0;
  }

  .semiselected-toggle::after {
    @apply h-1 rounded-none;
    top: 5px
  }
</style>
